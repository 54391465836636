import * as THREE from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import GUI from "lil-gui";
import { GPUComputationRenderer } from "three/addons/misc/GPUComputationRenderer.js";
import particlesVertexShader from "./shaders/particles/vertex.glsl";
import particlesFragmentShader from "./shaders/particles/fragment.glsl";
import gpgpuParticles from "./shaders/gpgpu/particles.glsl";
import { gsap } from "gsap";

/**
 * Base
 */
// Debug
const gui = new GUI({ width: 340 });
const debugObject = {};
// hide gui
gui.hide();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

// Loaders
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("/draco/");

const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
  pixelRatio: Math.min(window.devicePixelRatio, 2),
};

const checkMobile = () => {
  if (window.innerWidth < 768) {
    console.log("mobile");
    return true;
  }
  return false;
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;
  sizes.pixelRatio = Math.min(window.devicePixelRatio, 2);

  // Materials
  particles.material.uniforms.uResolution.value.set(
    sizes.width * sizes.pixelRatio,
    sizes.height * sizes.pixelRatio
  );

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(sizes.pixelRatio * 2);
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  35,
  sizes.width / sizes.height,
  0.1,
  100
);

// on mobile set it to 30
if (window.innerWidth < 768) {
  camera.position.set(0, 0, 30);
} else {
  camera.position.set(0, 0, 13);
}
scene.add(camera);

// // Controls
// const controls = new OrbitControls(camera, canvas);
// controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  // make it transparent
  alpha: true,
});

// make renderer transparent
renderer.setClearColor(0x000000, 0);

renderer.setSize(sizes.width, sizes.height);
// make renderer more hd
renderer.setPixelRatio(sizes.pixelRatio * 2);

debugObject.clearColor = "#000000";

/**
 * Load model
 */
const gltf = await gltfLoader.loadAsync("./logo-7.glb");

// Access the geometry of the loaded model
const geometry = gltf.scene.children[0].geometry;

// Apply the scaling transformation directly to the geometry
geometry.applyMatrix4(new THREE.Matrix4().makeScale(20, 20, 20));
// also rotate it on the x axis 90deg
geometry.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));

/**
 * Base geometry
 */

const baseGeometry = {};
baseGeometry.instance = geometry;
baseGeometry.count = baseGeometry.instance.attributes.position.count;
console.log(baseGeometry.count);

/**
 * GPU compute
 */
// Setup
const gpgpu = {};
gpgpu.size = Math.ceil(Math.sqrt(baseGeometry.count));
gpgpu.computation = new GPUComputationRenderer(
  gpgpu.size,
  gpgpu.size,
  renderer
);

// Base particles
const baseParticlesTexture = gpgpu.computation.createTexture();

for (let i = 0; i < baseGeometry.count; i++) {
  const i3 = i * 3;
  const i4 = i * 4;

  //   Position based on geometry
  baseParticlesTexture.image.data[i4 + 0] =
    baseGeometry.instance.attributes.position.array[i3 + 0];
  baseParticlesTexture.image.data[i4 + 1] =
    baseGeometry.instance.attributes.position.array[i3 + 1];
  baseParticlesTexture.image.data[i4 + 2] =
    baseGeometry.instance.attributes.position.array[i3 + 2];
  baseParticlesTexture.image.data[i4 + 3] = Math.random();
}

// Particles variable
gpgpu.particlesVariable = gpgpu.computation.addVariable(
  "uParticles",
  gpgpuParticles,
  baseParticlesTexture
);

gpgpu.computation.setVariableDependencies(gpgpu.particlesVariable, [
  gpgpu.particlesVariable,
]);

// Uniforms
gpgpu.particlesVariable.material.uniforms.uTime = new THREE.Uniform(0);
gpgpu.particlesVariable.material.uniforms.uDeltaTime = new THREE.Uniform(0);
gpgpu.particlesVariable.material.uniforms.uBase = new THREE.Uniform(
  baseParticlesTexture
);
gpgpu.particlesVariable.material.uniforms.uFlowFieldInfluence =
  new THREE.Uniform(0.461);

gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength =
  new THREE.Uniform(0.2);

gpgpu.particlesVariable.material.uniforms.uFlowFieldFrequency =
  new THREE.Uniform(1);

// Init
gpgpu.computation.init();

// Debug
gpgpu.debug = new THREE.Mesh(
  new THREE.PlaneGeometry(3, 3),
  new THREE.MeshBasicMaterial({
    map: gpgpu.computation.getCurrentRenderTarget(gpgpu.particlesVariable)
      .texture,
  })
);

gpgpu.debug.visible = false;
gpgpu.debug.position.x = 3;
scene.add(gpgpu.debug);

/**
 * Effects
 */

let scrolling = false;
const influenceIncrement = 0.001;
const strengthIncrement = 0.2 * 2;
const frequencyIncrement = 0.1 * 20;
const maxInfluence = 0.99;
const maxStrength = 100;
const maxFrequency = 200;
const sizeDecreaseThreshold = 50;
const sizeDecrement = 0.5 / 20; // Assuming you want the size to reach 0 gradually
const colorIncrement = 0.5 / (maxStrength / strengthIncrement); // Increment for uColor

// add an event listener on scrolling / touchpad scrolling
document.addEventListener("wheel", (event) => {
  // detect when the user is scrolling down
  if (event.deltaY > 0) {
    scrolling = true;
  }

  // when scrolling up, reset the uniforms
  if (event.deltaY < 0) {
    resetAllAnimations();
  }
});

// the same for swipe up and down
let touchstartY = 0;
let touchendY = 0;

document.addEventListener("touchstart", (event) => {
  touchstartY = event.changedTouches[0].screenY;
});

document.addEventListener("touchend", (event) => {
  touchendY = event.changedTouches[0].screenY;
  handleGesture();
});

function handleGesture() {
  if (touchendY < touchstartY) {
    scrolling = true;
  }

  if (touchendY > touchstartY) {
    resetAllAnimations();
  }
}

// Function to reset all animations and uniforms
function resetAllAnimations() {
  resetUniforms();
  resetCameraPosition();
  const element = document.querySelector(".main");
  element.style.opacity = 0;
  element.style.filter = "blur(10px)";
}

// Function to update uniforms and particle size
const updateUniformsAndSize = () => {
  const uniforms = gpgpu.particlesVariable?.material?.uniforms;
  if (scrolling && uniforms) {
    if (uniforms.uFlowFieldInfluence.value < maxInfluence) {
      uniforms.uFlowFieldInfluence.value += influenceIncrement;
    }

    if (uniforms.uFlowFieldStrength.value < maxStrength) {
      uniforms.uFlowFieldStrength.value += strengthIncrement;
      particles.material.uniforms.uColor.value += colorIncrement; // Synchronize uColor increment
    }

    if (uniforms.uFlowFieldFrequency.value < maxFrequency) {
      uniforms.uFlowFieldFrequency.value += frequencyIncrement;
    }

    if (uniforms.uFlowFieldStrength.value >= maxStrength) {
      scrolling = false;
    }
  }
};

/**
 * Particles
 */
const particles = {};

// Geometry
const particlesUvArray = new Float32Array(baseGeometry.count * 2);
const sizesArray = new Float32Array(baseGeometry.count);

for (let y = 0; y < gpgpu.size; y++) {
  for (let x = 0; x < gpgpu.size; x++) {
    const i = y * gpgpu.size + x;
    const i2 = i * 2;

    const uvX = (x + 0.5) / gpgpu.size;
    const uvY = (y + 0.5) / gpgpu.size;

    particlesUvArray[i2 + 0] = uvX;
    particlesUvArray[i2 + 1] = uvY;
  }
}

particles.geometry = new THREE.BufferGeometry();
particles.geometry.setDrawRange(0, baseGeometry.count);
particles.geometry.setAttribute(
  "aParticlesUv",
  new THREE.BufferAttribute(particlesUvArray, 2)
);
particles.geometry.setAttribute(
  "aSize",
  new THREE.BufferAttribute(sizesArray, 1)
);

// Material
particles.material = new THREE.ShaderMaterial({
  vertexShader: particlesVertexShader,
  fragmentShader: particlesFragmentShader,
  uniforms: {
    uSize: new THREE.Uniform(0.01),
    uResolution: new THREE.Uniform(
      new THREE.Vector2(
        sizes.width * sizes.pixelRatio,
        sizes.height * sizes.pixelRatio
      )
    ),
    uParticlesTexture: new THREE.Uniform(),
    uColor: new THREE.Uniform(Math.random() * 0.2),
  },
});

// Points
particles.points = new THREE.Points(particles.geometry, particles.material);
scene.add(particles.points);

/**
 * Tweaks
 */
gui.addColor(debugObject, "clearColor").onChange(() => {
  renderer.setClearColor(debugObject.clearColor);
});
gui
  .add(particles.material.uniforms.uSize, "value")
  .min(0)
  .max(0.01)
  .step(0.00001)
  .name("uSize");

gui
  .add(gpgpu.particlesVariable.material.uniforms.uFlowFieldInfluence, "value")
  .min(0)
  .max(1)
  .name("uFlowFieldInfluence");

gui
  .add(gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength, "value")
  .min(0)
  .max(10)
  .name("uFlowFieldStrength");

gui
  .add(gpgpu.particlesVariable.material.uniforms.uFlowFieldFrequency, "value")
  .min(0)
  .max(1)
  .step(0.001)
  .name("uFlowFieldFrequency");

// add guy for uColor
gui
  .add(particles.material.uniforms.uColor, "value")
  .min(0)
  .max(1)
  .step(0.001)
  .name("uColor");

/**
 * Animate
 */
let targetZ;
let cameraAnimation = null; // Variable to hold the current camera animation

if (window.innerWidth < 768) {
  targetZ = 15;
} else {
  targetZ = 20;
}

// update camera position by zooming out
function updateCameraPosition() {
  if (cameraAnimation) {
    cameraAnimation.kill(); // Kill any ongoing camera animation
  }
  cameraAnimation = gsap.to(camera.position, {
    z: targetZ,
    duration: 5, // duration of the animation in seconds
    ease: "power1.out", // different easing function for smooth start and stop
  });
}

let targetZReset;

if (window.innerWidth < 768) {
  targetZReset = 30;
} else {
  targetZReset = 13;
}

// update camera position by zooming in
function resetCameraPosition() {
  if (cameraAnimation) {
    cameraAnimation.kill(); // Kill any ongoing camera animation
  }
  cameraAnimation = gsap.to(camera.position, {
    z: targetZReset,
    duration: 3, // duration of the animation in seconds
    ease: "power1.out", // easing function for smooth start and stop
  });
}

// function that brings all the uniforms back to the initial state
function resetUniforms() {
  gpgpu.particlesVariable.material.uniforms.uFlowFieldInfluence.value = 0.461;
  gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength.value = 0.2;
  gpgpu.particlesVariable.material.uniforms.uFlowFieldFrequency.value = 1;
  particles.material.uniforms.uColor.value = Math.random() * 0.7;
  scrolling = false; // Stop scrolling animation
  console.log("reset");
}

const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  // Update uniforms and particle size
  updateUniformsAndSize();

  // gpgpu update
  if (gpgpu.particlesVariable?.material?.uniforms) {
    gpgpu.particlesVariable.material.uniforms.uTime.value = elapsedTime;
    gpgpu.particlesVariable.material.uniforms.uDeltaTime.value = deltaTime;
  }

  gpgpu.computation.compute();
  particles.material.uniforms.uParticlesTexture.value =
    gpgpu.computation.getCurrentRenderTarget(gpgpu.particlesVariable).texture;

  if (
    gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength.value >
    maxStrength
  ) {
    const element = document.querySelector(".main");
    element.style.opacity = 1;
    element.style.filter = "blur(0px)";
    updateCameraPosition();
  }

  if (
    gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength.value >
    maxStrength - sizeDecreaseThreshold
  ) {
  }

  if (
    gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength.value >
    maxStrength
  ) {
    setTimeout(() => {
      gpgpu.particlesVariable.material.uniforms.uFlowFieldInfluence.value = 0.99;
      gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength.value = 9;
      gpgpu.particlesVariable.material.uniforms.uFlowFieldFrequency.value = 0.198;
      const element = document.querySelector(".main");
      element.style.opacity = 1;
      element.style.filter = "blur(0px)";
      updateCameraPosition();
    }, 300);
  }
  // Render normal scene
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
